import React from "react";

const TIMEZONE_DATA = ["Indian Standard Time (IST)"];

const TimeZoneComponent = (props) => {
  const handleTimeZoneChange = (value) => {
    props.handleTimeZoneChange(value);
  };

  return (
    <div className="contest-start-time">
      <div className="contest-start-time-item">
        <label className="mc-label">Time Zone</label>
        <select
          value={props.timeZone}
          onChange={(e) => handleTimeZoneChange(e.target.value)}
          className="time-select"
        >
          {TIMEZONE_DATA.map((h) => (
            <option value={h}>{h}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TimeZoneComponent;
