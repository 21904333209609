import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useSelector, useDispatch } from "react-redux";
import { getUsers, toggleUserActivation } from "./services/userApi";
import UserInfoDrawer from "./component/UserInfoDrawer";
import userProfileDefaultPic from "../assets/images/user-profile.png";
import "./UserPage.css";
import AntSwitch from "../main/component/AntSwitch";

const UserPage = () => {
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [open, setOpen] = useState(false);

  const [searchByName, setSearchByName] = useState("");
  const [searchByLocation, setSearchByLocation] = useState("");

  const loadUsers = async () => {
    const { data } = await getUsers();
    setRows(data);
    setFilteredRows(data);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const activateUser = async (userId, active) => {
    try {
      const response = await toggleUserActivation(userId, active);
      await loadUsers();
    } catch (e) {
      console.log("error occurred");
    }
  };

  const columns = [
    {
      field: "",
      headerName: "ID",
      width: 200,
      renderCell: (params) => {
        return (
          <span
            onClick={() => {
              setSelectedRow(params.row);
              setOpen(true);
            }}
          >
            {params.row._id}
          </span>
        );
      },
    },
    {
      field: "firstname",
      headerName: "Artist",
      width: 150,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <img
              src={params.row.profilePhotoUrl || userProfileDefaultPic}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = userProfileDefaultPic;
              }}
              alt="profile-pic"
              width={15}
              height={15}
              style={{ borderRadius: "100%" }}
            />
            <span>{`${params.row.firstname} ${params.row.lastname}`}</span>
          </React.Fragment>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 230,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 120,
    },
    {
      field: "location.city",
      headerName: "Location",
      width: 200,
      renderCell: (params) => {
        return (
          <span>
            {params.row.location &&
              `${params.row.location.city} ${params.row.location.state} ${params.row.location.country} `}
          </span>
        );
      },
    },
    {
      field: "about",
      headerName: "About",
      width: 250,
    },
    {
      field: "active",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return <AntSwitch checked={params.row.active} 
        onChange={() => {
          activateUser(
            params.row._id,
            !params.row.active
          );
        }}/>;
      },
    },
  ];

  const search = () => {
    const theFilteredRows = rows.filter(
      (theRow) =>
        (searchByName
          ? theRow.firstname && theRow.firstname.toUpperCase().startsWith(searchByName.toUpperCase())
          : true) &&
        (searchByLocation
          ? theRow.location && theRow.location.city.toUpperCase().startsWith(searchByLocation.toUpperCase())
          : true)
    );

    setFilteredRows(theFilteredRows);
  };

  const reset = () => {
    setSearchByName("");
    setSearchByLocation("");
    setFilteredRows(rows);
  };
  return (
    <div
      style={{
        minHeight: 620,
        width: "100%",
      }}
    >
      <div
        style={{
          height: 530,
          width: "100%",
          display: "inline-block",
          paddingTop: 100,
        }}
      >
        <div className="search-by-name-container">
          <label className="mc-label">Name</label>
          <div>
            <input
              type="text"
              value={searchByName}
              onChange={(e) => setSearchByName(e.target.value)}
            />
          </div>
        </div>
        <div className="search-by-location-container">
          <label className="mc-label">Location</label>
          <div>
            <input
              type="text"
              value={searchByLocation}
              onChange={(e) => setSearchByLocation(e.target.value)}
            />
          </div>
        </div>
        <div className="search-by-date-btn" onClick={search}>
          Search
        </div>
        <div className="reset-btn" onClick={reset}>
          Reset
        </div>
        {filteredRows && filteredRows.length > 0 && (
          <DataGrid
            getRowId={(row) => row._id}
            rows={filteredRows}
            columns={columns}
            rowsPerPageOptions={[5, 10, 20, 50]}
            pageSize={10}
            disableSelectionOnClick
          />
        )}
        <UserInfoDrawer
          selectedRow={selectedRow}
          open={open}
          setOpen={setOpen}
        />
      </div>
    </div>
  );
};

export default UserPage;
