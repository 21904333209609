const getTodaysDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month =
    today.getMonth() + 1 < 10 ? `0${today.getMonth()}` : `${today.getMonth()}`;
  const date =
    today.getDate() + 1 < 10 ? `0${today.getDate()}` : `${today.getDate()}`;
  return `${year}-${month}-${date}`;
};

const getTodaysUsers = (users) => {
  const todaysUsers = users.filter(
    (theUser) => theUser.createdAt.split("T")[0] === getTodaysDate()
  );
  return todaysUsers;
};

export const userDataReducer = (state = {}, action) => {
  switch (action.type) {
    case "onLoadUsersData": {
      return {
        ...state,
        totalUsers: action.payload.length,
        todayRegisteredUser: getTodaysUsers(action.payload).length,
        users: [...action.payload.slice(0, 10)],
      };
    }
    case "onLoadAllUsersData": {
      return {
        ...state,
        totalUsers: action.payload.length,
        todayRegisteredUser: getTodaysUsers(action.payload).length,
        allUsers: [...action.payload],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
