import baseBackendApi from "../../main/configuration/baseBackendApi";

export const getEditorsPick = async () => {
    const response = baseBackendApi.get("songs/editorsPick/all");
    return response;
}

export const changeEditorsPick = async (songId, pick) => {
    const response = baseBackendApi.patch(`songs/${songId}/editorsPick?pick=${pick}`);
    return response.data;
}