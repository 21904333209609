import React from "react";

const ManageRolesPage = () => {
  return (
    <div
      style={{
        height: 620,
        width: "100%",
        display: "inline-block",
        paddingTop: 100,
      }}
      className="song-container"
    >
      this is main container page for manage role
    </div>
  );
};

export default ManageRolesPage;
