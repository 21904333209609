import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import "./request.css";
import { getSongRequests } from "./requestApi";

const RequestsPage = () => {
  const [requestData, setRequestData] = useState([]);
  const [requests, setRequests] = useState(requestData);
  const [searchByName, setSearchByName] = useState("");

  const loadRequestData = async () => {
    const data = await getSongRequests();
    const theData = data.map((d) => ({
      id: d["_id"],
      requestFrom: d.requester
        ? d.requester.firstname + " " + d.requester.lastname
        : "",
      requestTo: d.singer ? d.singer.firstname + " " + d.singer.lastname : "",
      dateOfRequest: d.createdAt,
      requestTitle: d.description,
      action: ["ACCEPT", "DECLINE"],
    }));

    setRequestData(theData);
    setRequests(theData);
  };

  useEffect(() => {
    loadRequestData();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "No",
      width: 100,
      renderCell: (params) => {
        return <p>{params.row.id}</p>;
      },
    },
    {
      field: "requestFrom",
      headerName: "Request From",
      width: 150,
      renderCell: (params) => {
        return (
          <strong style={{ color: "#000" }}>{params.row.requestFrom}</strong>
        );
      },
    },
    {
      field: "requestTo",
      headerName: "Request To",
      width: 150,
      renderCell: (params) => {
        return (
          <strong style={{ color: "#000" }}>{params.row.requestTo}</strong>
        );
      },
    },
    {
      field: "dateOfRequest",
      headerName: "Date of request",
      width: 200,
      renderCell: (params) => {
        return <span>{params.row.dateOfRequest}</span>;
      },
    },
    {
      field: "requestTitle",
      headerName: "Request Title",
      width: 200,
      renderCell: (params) => {
        return <span>{params.row.requestTitle}</span>;
      },
    },
    {
      field: "",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {params.row.action.map((theAction, index) => (
              <span key={index} className={theAction.toLowerCase()}>
                {theAction}
              </span>
            ))}
          </React.Fragment>
        );
      },
    },
  ];

  const search = () => {
    const theFilteredRows = requests.filter((theRow) =>
      searchByName
        ? theRow.requestFrom && theRow.requestFrom.toUpperCase().startsWith(searchByName.toUpperCase())
        : true
    );

    setRequests(theFilteredRows);
  };

  const reset = () => {
    setSearchByName("");
    setRequests(requestData);
  };

  return (
    <div
      style={{
        height: 620,
        width: "100%",
        display: "inline-block",
        paddingTop: 100,
      }}
      className="song-container"
    >
      <div
        style={{
          height: 450,
          width: "100%",
          display: "inline-block",
        }}
        className="song-container"
      >
        <div className="search-by-name-container">
          <label className="mc-label">Name</label>
          <div>
            <input
              type="text"
              value={searchByName}
              onChange={(e) => setSearchByName(e.target.value)}
            />
          </div>
        </div>
        <div className="search-by-date-btn" onClick={search}>
          Search
        </div>
        <div className="reset-btn" onClick={reset}>
          Reset
        </div>
        {requests && requests.length > 0 && (
          <DataGrid
            getRowId={(row) => row.id}
            rows={requests}
            columns={columns}
            rowsPerPageOptions={[5, 10, 20, 50]}
            pageSize={10}
            disableSelectionOnClick
          />
        )}
      </div>
    </div>
  );
};

export default RequestsPage;
