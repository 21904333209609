import baseBackendApi from "../../main/configuration/baseBackendApi";

export const getSongs = async (params) => {
    const response = await baseBackendApi.get("songs", {params});
    return response;
}

export const getSongsByGenre = async (genre) => {
    const response = await baseBackendApi.get(`songs/genre/${genre}`);
    return response;
}

export const toggleActivate = async (songId, active) => {
    const response = baseBackendApi.patch(`songs/${songId}/active?active=${active}`);
    return response.data;
}

export const promoteSong = async (songId, active) => {
    const response = baseBackendApi.patch(`songs/${songId}/promote?promote=${active}`);
    return response.data;
}