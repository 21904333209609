import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { userReducer } from "../../reducers/loginReducer";
import { songReducer } from "../../../song/reducer/songReducer";
import { userDataReducer } from "../../../user/reducer/userReducer";
import { requestReducer } from "../../../requests/requestReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import { tipsReducer } from "../../../tips/tipsReducer";
import { reportReducer } from "../../../reports/reportReducer";
import { songRequestDeletionReducer } from "../../../requestdeletion/requestForDeletionReducer";
import { manageGenreReducer } from "../../../managegenre/managegenreReducer";

const initialState = {};
const store = createStore(
  combineReducers({
    user: userReducer,
    song: songReducer,
    userData: userDataReducer,
    requests: requestReducer,
    tips: tipsReducer,
    reports: reportReducer,
    songRequest: songRequestDeletionReducer,
    settings: manageGenreReducer
  }),
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
