import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { allVideoOfTheDay } from "./services/songApi";
import SongInfoDrawer from "./component/SongInfoDrawer";
import { CircularProgress, Switch } from "@material-ui/core";
import * as moment from "moment";
import AntSwitch from "../main/component/AntSwitch";
import DatePicker from "react-datepicker";
import userProfileDefaultPic from "../assets/images/user-profile.png";

import "react-datepicker/dist/react-datepicker.css";
import "../main/main.css";
import "./VideoOfTheDay.css";

const VideoOfTheDay = () => {
  // const [rows, setRows] = useState([]);
  const rows = useSelector((state) => state.song.songs) || [];
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);

  const loadSongs = async () => {
    // setLoading(true);
    const response = await allVideoOfTheDay();
    dispatch({
      type: "onLoadSongs",
      payload: response.data,
    });
    // setLoading(false);
  };

  useEffect(() => {
    loadSongs();
  }, []);

  const columns = [
    {
      field: "",
      headerName: "Id",
      width: 200,
      renderCell: (params) => {
        return (
          <span
            onClick={() => {
              setSelectedRow(params.row);
              setOpen(true);
            }}
          >
            {params.row._id}{" "}
          </span>
        );
      },
    },
    {
      field: "title",
      headerName: "Video Title",
      width: 150,
      renderCell: (params) => {
        return <strong style={{ color: "#000" }}>{params.row.title}</strong>;
      },
    },
    {
      field: "createdAt",
      headerName: "Date & Time",
      width: 150,
      renderCell: (params) => {
        return (
          <span>
            {moment(params.row.createdAt).format("DD-MMM-YYYY hh:mm A")}
          </span>
        );
      },
    },
    {
      field: "user.firstname",
      headerName: "Artist",
      width: 150,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <img
              src={
                (params.row.user && params.row.user.profilePhotoUrl) ||
                userProfileDefaultPic
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = userProfileDefaultPic;
              }}
              alt="profile-pic"
              width={15}
              height={15}
              style={{ borderRadius: "100%" }}
            />
            <span style={{ marginLeft: "5px", textTransform: "capitalize" }}>
              {`${params.row.user && params.row.user.firstname} ${
                params.row.user && params.row.user.lastname
              }`}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      field: "votesCount",
      headerName: "Votes Count",
      width: 100,
      renderCell: (params) => {
        return <span>{params.row.votesCount}</span>;
      },
    },
  ];

  return (
    <div
      style={{
        height: 592,
        width: "100%",
        display: "inline-block",
        paddingTop: 100,
      }}
      className="song-container"
    >
      {rows && rows.length > 0 && (
        <DataGrid
          getRowId={(row) => row._id}
          rows={rows}
          columns={columns}
          rowsPerPageOptions={[5, 10, 20, 50]}
          pageSize={10}
          disableSelectionOnClick
        />
      )}
      <SongInfoDrawer selectedRow={selectedRow} open={open} setOpen={setOpen} />
    </div>
  );
};

export default VideoOfTheDay;
