import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({component: Component, ...rest}) => {
    const currentUser = useSelector((state) => state.user.loggedInUser);
    console.log('current user', currentUser);
    return (
        <Route
            {...rest}
            render={(props) => (currentUser && currentUser.login && currentUser.access_token) ?
                <Component {...props} /> : <Redirect to="/" />} />
    );
}

export default ProtectedRoute;