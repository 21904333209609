import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { ExitToApp } from "@material-ui/icons";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DvrIcon from "@mui/icons-material/Dvr";
import LayersIcon from "@mui/icons-material/Layers";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { BrowserRouter as Router, Link, Switch } from "react-router-dom";
import ContestPage from "../../contest/ContestPage";
import EditorsPickPage from "../../editorspick/EditorsPickPage";
import ManageGenrePage from "../../managegenre/ManageGenrePage";
import ManageRolesPage from "../../manageroles/ManageRolesPage";
import NotificationPage from "../../notifications/NotificationPage";
import ReportsPage from "../../reports/ReportsPage";
import RequestDeletionPage from "../../requestdeletion/RequestDeletionPage";
import RequestPage from "../../requests/RequestsPage";
import SongMainPage from "../../song/SongMainPage";
import TipsPage from "../../tips/TipsPage";
import UserPage from "../../user/UsersPage";
import VideoOfTheDay from "../../vidoeoftheday/VideoOfTheDay";
import "../leftDrawerMenu.css";
import "../main.css";
import HomePage from "./HomePage";
import ProtectedRoute from "./ProtectedRoute";

const menus = [
  {
    title: "Dashboard",
    link: "/home",
    icon: <DashboardIcon style={{ fill: "#fff" }} />,
  },
  {
    title: "Contest",
    link: "/contest",
    icon: <VideoLibraryIcon style={{ fill: "#fff" }} />,
  },
  {
    title: "All Videos",
    link: "/song",
    icon: <VideocamIcon style={{ fill: "#fff" }} />,
  },
  {
    title: "All Users",
    link: "/user",
    icon: <AccountCircleIcon style={{ fill: "#fff" }} />,
  },
  {
    title: "Tips",
    link: "/tips",
    icon: <CurrencyRupeeIcon style={{ fill: "#fff" }} />,
  },
  {
    title: "Requests",
    link: "/requests",
    icon: <AssignmentIcon style={{ fill: "#fff" }} />,
  },
  {
    title: "Editors Pick",
    link: "/editorsPick",
    icon: <LayersIcon style={{ fill: "#fff" }} />,
  },
  {
    title: "Manage Genre & Times",
    link: "/manageGenre",
    icon: <AccessTimeIcon style={{ fill: "#fff" }} />,
  },
  {
    title: "Manage Roles",
    link: "/manageRoles",
    icon: <DvrIcon style={{ fill: "#fff" }} />,
  },
  {
    title: "Reports",
    link: "/reports",
    icon: <ReportGmailerrorredIcon style={{ fill: "#fff" }} />,
  },
  {
    title: "Request for Deletion",
    link: "/requestDeletion",
    icon: <DeleteOutlineIcon style={{ fill: "#fff" }} />,
  },
  {
    title: "Notifications",
    link: "/notificaitons",
    icon: <NotificationsNoneIcon style={{ fill: "#fff" }} />,
  },
];
const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: "hidden",
  },
  drawerContainer: {
    overflow: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function MainPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedMenu, setSelectedMenu] = React.useState("Dashboard");

  const handleLogout = () => {
    dispatch({ type: "onLogout" });
    history.push("/");
  };

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List>
              {menus.map((option) => (
                <Link
                  key={option.title}
                  to={option.link}
                  className={`${
                    selectedMenu === option.title ? "link-selected" : "link"
                  }`}
                >
                  <ListItem
                    button
                    onClick={() => setSelectedMenu(option.title)}
                  >
                    <ListItemIcon> {option.icon} </ListItemIcon>
                    <ListItemText primary={option.title} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </div>
        </Drawer>
        <main className={classes.content}>
          <AppBar>
            <Toolbar>
              <Typography className="music-card-logo-text" style={{ flex: 1 }}>
                MusicCard
              </Typography>
              <div className="user-profile">
                <Typography className="user-profile-welcome">
                  Welcome, Admin
                </Typography>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleLogout}
                  color="inherit"
                >
                  <ExitToApp />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <Switch>
            <ProtectedRoute path="/home" component={HomePage} />
            <ProtectedRoute path="/song" component={SongMainPage} />
            <ProtectedRoute path="/user" component={UserPage} />
            <ProtectedRoute path="/editorsPick" component={EditorsPickPage} />
            <ProtectedRoute path="/videooftheday" component={VideoOfTheDay} />
            <ProtectedRoute path="/contest" component={ContestPage} />
            <ProtectedRoute path="/tips" component={TipsPage} />
            <ProtectedRoute path="/requests" component={RequestPage} />
            <ProtectedRoute path="/manageGenre" component={ManageGenrePage} />
            <ProtectedRoute path="/manageRoles" component={ManageRolesPage} />
            <ProtectedRoute path="/reports" component={ReportsPage} />
            <ProtectedRoute
              path="/requestDeletion"
              component={RequestDeletionPage}
            />
            <ProtectedRoute
              path="/notificaitons"
              component={NotificationPage}
            />
          </Switch>
        </main>
      </div>
    </Router>
  );
}
