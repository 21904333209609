import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import userProfileDefaultPic from "../assets/images/user-profile.png";
import { getReportedSongs } from "./reportApi";

import "react-datepicker/dist/react-datepicker.css";
import "../main/main.css";

const ReportsPage = () => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);

  const loadReports = async () => {
    const data = await getReportedSongs();
    const theData = data.map((d) => ({
      id: d["_id"],
      title: d.song.title,
      time: d.createdAt.split("T")[1].split(".")[0],
      date: d.createdAt.split("T")[0],
      reportedBy: d.song.user
        ? d.song.user.firstname + " " + d.song.user.lastname
        : "",
      user: d.user ? d.user.firstname + " " + d.user.lastname : " ",
      description: d.reason,
    }));

    setRows(theData);
    setFilteredRows(theData);
  };

  useEffect(() => {
    loadReports();
  }, []);

  const columns = [
    {
      field: "",
      headerName: "Id",
      width: 200,
      renderCell: (params) => {
        return <span>{params.row.id}</span>;
      },
    },
    {
      field: "title",
      headerName: "Video Title",
      width: 150,
      renderCell: (params) => {
        return <strong style={{ color: "#000" }}>{params.row.title}</strong>;
      },
    },
    {
      field: "user",
      headerName: "Owner",
      width: 150,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <img
              src={
                (params.row.user && params.row.user.profilePhotoUrl) ||
                userProfileDefaultPic
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = userProfileDefaultPic;
              }}
              alt="profile-pic"
              width={15}
              height={15}
              style={{ borderRadius: "100%" }}
            />
            <span style={{ marginLeft: "5px", textTransform: "capitalize" }}>
              {params.row.user}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      field: "reportedBy",
      headerName: "Reported By",
      width: 150,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <img
              src={
                (params.row.user && params.row.user.profilePhotoUrl) ||
                userProfileDefaultPic
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = userProfileDefaultPic;
              }}
              alt="profile-pic"
              width={15}
              height={15}
              style={{ borderRadius: "100%" }}
            />
            <span style={{ marginLeft: "5px", textTransform: "capitalize" }}>
              {params.row.reportedBy}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      field: "time",
      headerName: "Time",
      width: 100,
      renderCell: (params) => {
        return <span>{params.row.time}</span>;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      renderCell: (params) => {
        return <span>{params.row.date}</span>;
      },
    },
    {
      field: "description",
      headerName: "description",
      width: 100,
    },
  ];

  const [searchByName, setSearchByName] = useState("");
  const [searchByVideoTitle, setSearchByVideoTitle] = useState("");

  const search = () => {
    const theFilteredRows = rows.filter(
      (theRow) =>
        (searchByName ? theRow.user.toUpperCase().startsWith(searchByName.toUpperCase()) : true) &&
        (searchByVideoTitle
          ? theRow.title.toUpperCase().startsWith(searchByVideoTitle.toUpperCase())
          : true)
    );

    setFilteredRows(theFilteredRows);
  };

  const reset = () => {
    setSearchByName("");
    setSearchByVideoTitle("");
    setFilteredRows(rows);
  };
  return (
    <div
      style={{
        minHeight: 620,
        width: "100%",
      }}
    >
      <div
        style={{
          height: 530,
          width: "100%",
          display: "inline-block",
          paddingTop: 100,
        }}
        className="song-container"
      >
        <div className="search-by-name-container">
          <label className="mc-label">Name</label>
          <div>
            <input
              type="text"
              value={searchByName}
              onChange={(e) => setSearchByName(e.target.value)}
            />
          </div>
        </div>
        <div className="search-by-location-container">
          <label className="mc-label">Video Title</label>
          <div>
            <input
              type="text"
              value={searchByVideoTitle}
              onChange={(e) => setSearchByVideoTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="search-by-date-btn" onClick={search}>
          Search
        </div>
        <div className="reset-btn" onClick={reset}>
          Reset
        </div>
        {filteredRows && filteredRows.length > 0 && (
          <DataGrid
            getRowId={(row) => row.id}
            rows={filteredRows}
            columns={columns}
            rowsPerPageOptions={[5, 10, 20, 50]}
            pageSize={10}
            disableSelectionOnClick
          />
        )}
      </div>
    </div>
  );
};

export default ReportsPage;
