import React from "react";
import ReactPlayer from "react-player";
import { Chip, Drawer, Typography } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import * as moment from "moment";

const SongInfoDrawer = ({ selectedRow, open, setOpen }) => {
  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      className="song-drawer"
    >
      <div>
        {selectedRow && (
          <>
            <ReactPlayer
              width="400px"
              height="250px"
              style={{ padding: "20px 40px" }}
              url={selectedRow.songUrl}
              light={selectedRow.coverPhotoUrl}
              playing={true}
              controls={true}
            />
            <div className="song-info-container">
              <img
                className="song-cover-photo"
                src={selectedRow.coverPhotoUrl}
                alt="cover"
                height="50"
                width="50"
              />
              <br />
              <Typography className="song-title">
                <strong>{selectedRow.title}</strong>
              </Typography>
              <p className="song-about">{selectedRow.about}</p>
              <div className="info-table">
                <div class="info-row">
                  <div className="info-cell song-date">
                    <p>Date &amp; Time</p>
                    <p className="val">
                      <strong>
                        {moment(selectedRow.createdAt).format(
                          "DD-MMM-YYYY hh:mm A"
                        )}
                      </strong>
                    </p>
                  </div>
                  <div className="info-cell song-genre">
                    <p>Genre</p>
                    <p className="val">
                      <strong>{selectedRow.genre}</strong>
                    </p>
                  </div>
                </div>
                <div class="info-row">
                  <div className="info-cell song-date">
                    <p>Location</p>
                    <p className="val">
                      <strong>
                        {selectedRow.user.location ? 
                        `${selectedRow.user.location.city} ${selectedRow.user.location.state} ${selectedRow.user.location.country}`
                        : '-'}
                      </strong>
                    </p>
                  </div>
                  <div className="info-cell song-genre">
                    <p>Tags</p>
                    <p className="val">
                      <strong>{selectedRow.tags || '-' }</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
};

export default SongInfoDrawer;
