import React from "react";

const HOURS_DATA = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
const MINUTES_DATA = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
const AM_PM_DATA = ["AM", "PM"];

const TimeComponent = (props) => {
  const handleTimeChange = (name, value) => {
    props.handleTimeChange({
      ...props.time,
      [name]: value,
    });
  };

  return (
    <div className="contest-start-time">
      <div className="contest-start-time-item">
        <label className="mc-label">Hours</label>
        <select
          value={props.time.hh}
          onChange={(e) => handleTimeChange("hh", e.target.value)}
          className="time-select"
        >
          {HOURS_DATA.map((h) => (
            <option value={h}>{h}</option>
          ))}
        </select>
      </div>
      <div className="contest-start-time-item">
        <label className="mc-label">Minutes</label>
        <select
          value={props.time.mm}
          onChange={(e) => handleTimeChange("mm", e.target.value)}
          className="time-select"
        >
          {MINUTES_DATA.map((h) => (
            <option value={h}>{h}</option>
          ))}
        </select>
      </div>
      <div className="contest-start-time-item">
        <select
          value={props.time.ampm}
          onChange={(e) => handleTimeChange("ampm", e.target.value)}
          className="time-select"
        >
          {AM_PM_DATA.map((h) => (
            <option value={h}>{h}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TimeComponent;
