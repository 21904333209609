import React from "react";

const DAYS_DATA = [
  "Sunday",
  "Monday",
  "Tuesday",
  "wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Last Saturday of month",
  "Last Sunday of month",
];

const DaysComponent = (props) => {
  const handleDaysChange = (value) => {
    props.handleDaysChange(value);
  };

  return (
    <div className="contest-start-time">
      <div className="contest-start-time-item">
        <label className="mc-label">{props.label}</label>
        <select
          value={props.day}
          onChange={(e) => handleDaysChange(e.target.value)}
          className="time-select"
        >
          {DAYS_DATA.map((h) => (
            <option value={h}>{h}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DaysComponent;
