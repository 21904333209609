import baseBackendApi from "../main/configuration/baseBackendApi";

export const getAllNotificationForAdmin = async () => {
  const response = await baseBackendApi.get("notifications/admin");
  return response;
};

export const saveNotificationForAdmin = async (payload) => {
  const response = await baseBackendApi.post("notifications", payload);
  return response.data;
};
