import baseBackendApi from "../main/configuration/baseBackendApi";

export const getManageGenreRecords = async () => {
  const response = await baseBackendApi.get("/managegenre");
  return response.data;
};

export const postManageGenre = async (payload) => {
  const response = await baseBackendApi.post("/managegenre", payload);
  return response.data;
};
