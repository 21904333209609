import baseBackendApi from "../../main/configuration/baseBackendApi";

export const getUsers = async () => {
    const response = await baseBackendApi.get("users");
    return response;
}

export const toggleUserActivation = async (userId, active) => {
    const response = await baseBackendApi.patch(`users/${userId}/active?active=${active}`);
    return response.data;
}