import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import LoginPage from './main/component/LoginPage';
import MainPage from './main/component/MainPage';
import Store from './main/configuration/store/store'
import ProtectedRoute from './main/component/ProtectedRoute';

export default function App() {

  return (
    <Provider store={Store}>
      <Router>
        <Switch>
          <Route exact path="/">
            <LoginPage />
          </Route>
          <ProtectedRoute path="/home">
            <MainPage />
          </ProtectedRoute>
        </Switch>
      </Router>
    </Provider >
  );
}
