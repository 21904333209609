import React, { useState } from "react";
import {
  getAllNotificationForAdmin,
  saveNotificationForAdmin,
} from "./notificationApi";
import "./notification.css";

const NotificationPage = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [description, setDescription] = useState("");

  const loadNotificationData = async () => {
    const response = await getAllNotificationForAdmin();
    setNotificationData(response.data);
  };
  useState(() => {
    loadNotificationData();
  }, []);

  const saveNotification = async () => {
    await saveNotificationForAdmin({ description });
    await loadNotificationData();
    setDescription("");
  };

  return (
    <div
      style={{
        height: 620,
        width: "100%",
        display: "inline-block",
        paddingTop: 100,
      }}
      className="song-container"
    >
      <div className="add-genre-container">
        <input
          type="text"
          className="add-notification-input"
          placeholder="Add New Notification"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <button className="btn-save" onClick={saveNotification} disabled={!description.trim()}>
          Save Notification
        </button>
        <div className="search-by-date-btn" onClick={() => setDescription("")}>
          Reset
        </div>
      </div>
      {notificationData.map((notification) => (
        <div className="mc-notification">{notification.description}</div>
      ))}
    </div>
  );
};

export default NotificationPage;
