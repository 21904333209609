import React from "react";

const ContestPage = () => {
  return (
    <div
      style={{
        height: 592,
        width: "100%",
        display: "inline-block",
        paddingTop: 100,
      }}
      className="song-container"
    >
      this is main container page for container
    </div>
  );
};

export default ContestPage;
