import { DataGrid } from "@material-ui/data-grid";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import userProfileDefaultPic from "../assets/images/user-profile.png";
import AntSwitch from "../main/component/AntSwitch";
import SongInfoDrawer from "./component/SongInfoDrawer";
import { getSongs, promoteSong, toggleActivate } from "./services/songApi";

import "react-datepicker/dist/react-datepicker.css";
import "../main/main.css";
import "./SongMainPage.css";

const SongMainPage = () => {
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [filteredRows, setFilteredRows] = useState(rows);
  // const [loading, setLoading] = useState(false);

  const loadSongs = async () => {
    // setLoading(true);
    const { data } = await getSongs();
    setRows(data);
    setFilteredRows(data);
    // setLoading(false);
  };

  useEffect(() => {
    loadSongs();
  }, []);

  const promoteSongs = async (songId, promote) => {
    console.log("switch change event called");
    try {
      const response = await promoteSong(songId, promote);
      await loadSongs();
    } catch (e) {
      console.log("error occurred");
    }
  };

  const activateDeactivateSongs = async (songId, active) => {
    console.log("switch change event called");
    try {
      const response = await toggleActivate(songId, active);
      await loadSongs();
    } catch (e) {
      console.log("error occurred");
    }
  };

  const columns = [
    {
      field: "",
      headerName: "Id",
      width: 200,
      renderCell: (params) => {
        return (
          <span
            onClick={() => {
              setSelectedRow(params.row);
              setOpen(true);
            }}
          >
            {params.row._id}{" "}
          </span>
        );
      },
    },
    {
      field: "title",
      headerName: "Video Title",
      width: 150,
      renderCell: (params) => {
        return <strong style={{ color: "#000" }}>{params.row.title}</strong>;
      },
    },
    {
      field: "createdAt",
      headerName: "Date & Time",
      width: 150,
      renderCell: (params) => {
        return (
          <span>
            {moment(params.row.createdAt).format("DD-MMM-YYYY hh:mm A")}
          </span>
        );
      },
    },
    {
      field: "genre",
      headerName: "Genre",
      width: 100,
    },
    {
      field: "user.firstname",
      headerName: "Artist",
      width: 150,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <img
              src={
                (params.row.user && params.row.user.profilePhotoUrl) ||
                userProfileDefaultPic
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = userProfileDefaultPic;
              }}
              alt="profile-pic"
              width={15}
              height={15}
              style={{ borderRadius: "100%" }}
            />
            <span style={{ marginLeft: "5px", textTransform: "capitalize" }}>
              {`${params.row.user ? params.row.user.firstname : ""} ${
                params.row.user ? params.row.user.lastname : ""
              }`}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      field: "user.location.city",
      headerName: "Location",
      width: 200,
      renderCell: (params) => {
        return (
          <span>
            {params.row.user &&
              params.row.user.location &&
              `${params.row.user.location.city} ${params.row.user.location.state} ${params.row.user.location.country} `}
          </span>
        );
      },
    },
    {
      field: "competeForVideoContest",
      headerName: "In Contest",
      width: 100,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {params.row.competeForVideoContest ? (
              <span className="contest-yes"> YES </span>
            ) : (
              <span className="contest-no"> No </span>
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <AntSwitch
            checked={params.row.active}
            onChange={() => {
              activateDeactivateSongs(params.row._id, !params.row.active);
            }}
          />
        );
      },
    },
    {
      field: "promote",
      headerName: "Promote",
      width: 100,
      renderCell: (params) => {
        return (
          <AntSwitch
            checked={params.row.promote}
            onChange={() => {
              promoteSongs(params.row._id, !params.row.promote);
            }}
          />
        );
      },
    },
  ];

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("All");
  const [searchByName, setSearchByName] = useState("");
  const [searchByLocation, setSearchByLocation] = useState("");

  const search = () => {
    // add filter on rows data for the songs
    const theStartDate = new Date(startDate);
    const theEndDate = new Date(endDate);
    theStartDate.setHours(0);
    theStartDate.setMinutes(0);
    theStartDate.setSeconds(0);
    theEndDate.setHours(23);
    theEndDate.setMinutes(59);
    theEndDate.setSeconds(59);

    const theFilteredRows = rows.filter(
      (theRow) =>
        (startDate && endDate
          ? new Date(theRow.createdAt) >= theStartDate &&
            new Date(theRow.createdAt) <= theEndDate
          : true) &&
        (selectedGenre !== "All" ? selectedGenre === theRow.genre : true) &&
        (searchByName
          ? theRow.user && theRow.user.firstname.toUpperCase().startsWith(searchByName.toUpperCase())
          : true) &&
        (searchByLocation
          ? theRow.user &&
            theRow.user.location &&
            theRow.user.location.city.toUpperCase().startsWith(searchByLocation.toUpperCase())
          : true)
    );

    setFilteredRows(theFilteredRows);
  };

  const reset = () => {
    setStartDate("");
    setEndDate("");
    setSelectedGenre("All");
    setSearchByName("");
    setSearchByLocation("");
    setFilteredRows(rows);
  };
  return (
    <div
      style={{
        minHeight: 620,
        width: "100%",
      }}
    >
      <div
        style={{
          height: 530,
          width: "100%",
          display: "inline-block",
          paddingTop: 100,
        }}
        className="song-container"
      >
        <div className="search-by-date">
          <label>
            <strong>Date from</strong>
          </label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </div>
        <div className="search-by-date">
          <label>
            <strong>Date to</strong>
          </label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>
        <div className="search-by-genre-container">
          <label className="mc-label">Genre</label>
          <div>
            <select
              value={selectedGenre}
              label="Genre"
              onChange={(e) => setSelectedGenre(e.target.value)}
              className="search-by-genre"
            >
              <option value={"All"}>All</option>
              <option value={"Classical"}>Classical</option>
              <option value={"Bollywood"}>Bollywood</option>
            </select>
          </div>
        </div>
        <div className="search-by-name-container">
          <label className="mc-label">Name</label>
          <div>
            <input
              type="text"
              value={searchByName}
              onChange={(e) => setSearchByName(e.target.value)}
            />
          </div>
        </div>
        <div className="search-by-location-container">
          <label className="mc-label">Location</label>
          <div>
            <input
              type="text"
              value={searchByLocation}
              onChange={(e) => setSearchByLocation(e.target.value)}
            />
          </div>
        </div>
        <div className="search-by-date-btn" onClick={search}>
          Search
        </div>
        <div className="reset-btn" onClick={reset}>
          Reset
        </div>
        {filteredRows && filteredRows.length > 0 && (
          <DataGrid
            getRowId={(row) => row._id}
            rows={filteredRows}
            columns={columns}
            rowsPerPageOptions={[5, 10, 20, 50]}
            pageSize={10}
            disableSelectionOnClick
          />
        )}
        <SongInfoDrawer
          selectedRow={selectedRow}
          open={open}
          setOpen={setOpen}
        />
      </div>
    </div>
  );
};

export default SongMainPage;
