export const userReducer = (state = {}, action) => {
    switch (action.type) {
        case 'onLogin': {
            return {
                ...state,
                loggedInUser: {
                    ...action.payload
                }
            }
        }
        case 'onLogout': {
            return {
                ...state,
                loggedInUser: null
            }
        }
        default: {
            return state;
        }
    }
}
