const getTodaysDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month =
    today.getMonth() + 1 < 10 ? `0${today.getMonth()}` : `${today.getMonth()}`;
  const date =
    today.getDate() + 1 < 10 ? `0${today.getDate()}` : `${today.getDate()}`;
  return `${year}-${month}-${date}`;
};

const getTodaysSongs = (songs) => {
  const todaysSongs = songs.filter(
    (theSong) => theSong.createdAt.split("T")[0] === getTodaysDate()
  );
  return todaysSongs;
};

export const songReducer = (state = {}, action) => {
  switch (action.type) {
    case "onLoadSongs": {
      return {
        ...state,
        totalSongs: action.payload.length,
        todayUploadedSongs: getTodaysSongs(action.payload).length,
        songs: [...action.payload.slice(0, 10)],
      };
    }
    case "onLoadAllSongs": {
      return {
        ...state,
        totalSongs: action.payload.length,
        todayUploadedSongs: getTodaysSongs(action.payload).length,
        allSongs: [...action.payload],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
