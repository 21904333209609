import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { uuid } from "uuidv4";
import "./tips.css";
import { getAllTips } from "./tipsApi";

const TipsPage = () => {
  const [tipsData, setTipsData] = useState([]);
  const [tips, setTips] = useState([]);
  const [searchByName, setSearchByName] = useState("");
  const [searchByTransactionId, setSearchByTransactionId] = useState("");

  const loadTipsData = async () => {
    const data = await getAllTips();
    const theData = data.map((d) => ({
      id: d["_id"],
      transactionFrom: d.donor
        ? d.donor.firstname + " " + d.donor.lastname
        : "",
      transactionTo: d.singer
        ? d.singer.firstname + " " + d.singer.lastname
        : "",
      time: d.createdAt.split("T")[1].split(".")[0],
      transationDate: d.createdAt.split("T")[0],
      amount: d.amount,
      transactionId: uuid(),
      status: "successful",
    }));

    setTipsData(theData);
    setTips(theData);
  };

  useEffect(() => {
    loadTipsData();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "No",
      width: 100,
      renderCell: (params) => {
        return <p>{params.row.id}</p>;
      },
    },
    {
      field: "transactionFrom",
      headerName: "Transaction From",
      width: 150,
      renderCell: (params) => {
        return (
          <strong style={{ color: "#000" }}>
            {params.row.transactionFrom}
          </strong>
        );
      },
    },
    {
      field: "transactionTo",
      headerName: "Transaction To",
      width: 150,
      renderCell: (params) => {
        return (
          <strong style={{ color: "#000" }}>{params.row.transactionTo}</strong>
        );
      },
    },
    {
      field: "time",
      headerName: "Time",
      width: 100,
      renderCell: (params) => {
        return <span>{params.row.time}</span>;
      },
    },
    {
      field: "transationDate",
      headerName: "Date",
      width: 120,
      renderCell: (params) => {
        return <span>{params.row.transationDate}</span>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 80,
      renderCell: (params) => {
        return <span>{params.row.amount}</span>;
      },
    },
    {
      field: "transactionId",
      headerName: "Transaction Id",
      width: 250,
      renderCell: (params) => {
        return <span>{params.row.transactionId}</span>;
      },
    },
    {
      field: "",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {params.row.status === "successful" && (
              <span className="transaction-success">
                {params.row.status.toUpperCase()}
              </span>
            )}
            {params.row.status === "failed" && (
              <span className="transaction-failed">
                {params.row.status.toUpperCase()}
              </span>
            )}
          </React.Fragment>
        );
      },
    },
  ];

  const search = () => {
    const theFilteredRows = tips.filter(
      (theRow) =>
        (searchByName
          ? theRow.transactionFrom &&
            theRow.transactionFrom.toUpperCase().startsWith(searchByName.toUpperCase())
          : true) &&
        (searchByTransactionId
          ? theRow.transactionId &&
            theRow.transactionId.toUpperCase().startsWith(searchByTransactionId.toUpperCase())
          : true)
    );

    setTips(theFilteredRows);
  };

  const reset = () => {
    setSearchByName("");
    setSearchByTransactionId("");
    setTips(tipsData);
  };

  return (
    <div
      style={{
        height: 620,
        width: "100%",
        display: "inline-block",
        paddingTop: 100,
      }}
      className="song-container"
    >
      <div className="search-by-name-container">
        <label className="mc-label">Name</label>
        <div>
          <input
            type="text"
            value={searchByName}
            onChange={(e) => setSearchByName(e.target.value)}
          />
        </div>
      </div>
      <div className="search-by-location-container">
        <label className="mc-label">Transaction Id</label>
        <div>
          <input
            type="text"
            value={searchByTransactionId}
            onChange={(e) => setSearchByTransactionId(e.target.value)}
          />
        </div>
      </div>
      <div className="search-by-date-btn" onClick={search}>
        Search
      </div>
      <div className="reset-btn" onClick={reset}>
        Reset
      </div>
      {tips && tips.length > 0 && (
        <DataGrid
          getRowId={(row) => row.id}
          rows={tips}
          columns={columns}
          rowsPerPageOptions={[5, 10, 20, 50]}
          pageSize={10}
          disableSelectionOnClick
        />
      )}
    </div>
  );
};

export default TipsPage;
