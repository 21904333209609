import React from "react";

const ENTRY_DATA = [
  "All",
  "Only days highlighted"
];

const EntryComponent = (props) => {
  const handleEntryChange = (value) => {
    props.handleEntryChange(value);
  };

  return (
    <div className="contest-start-time">
      <div className="contest-start-time-item">
        <label className="mc-label">Define Entry</label>
        <select
          value={props.entry}
          onChange={(e) => handleEntryChange(e.target.value)}
          className="time-select"
        >
          {ENTRY_DATA.map((h) => (
            <option value={h}>{h}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default EntryComponent;
