import axios from "axios";

// production - http://65.2.178.34:7000
// dev - http://15.207.19.86:7000
// local - http://localhost:7000
// lightsail - 'http://52.43.54.211:7000'
const baseBackendApi = new axios.create({
  baseURL: 'https://adminapi.musiccard.app',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const setGlobalAuthentication = (token) => {
  baseBackendApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export default baseBackendApi;
