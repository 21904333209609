export const tipsReducer = (state = {}, action) => {
  switch (action.type) {
    case "onLoadTips": {
      return {
        ...state,
        tipsData: [...action.payload],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
