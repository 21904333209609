export const songRequestDeletionReducer = (state = {}, action) => {
  switch (action.type) {
    case "onLoadAllDeletionSongRequest": {
      return {
        ...state,
        deletionRequest: [...action.payload],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
