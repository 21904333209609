import React from "react";
import "../DashboardPage.css";

const DashboardInfo = ({count, text, date, className}) => {
  return (
    <div className={`dashboard-info ${className}`}>
      <p className="dashboard-info-count">{count}</p>
      <p className="dashboard-info-text">{text}</p>
      <p className="dashboard-info-date">{date}</p>
    </div>
  );
};

export default DashboardInfo;
