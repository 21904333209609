export const requestReducer = (state = {}, action) => {
  switch (action.type) {
    case "onLoadRequests": {
      return {
        ...state,
        songsRequests: [...action.payload],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
