import React, { useEffect, useState } from "react";
import TimeComponent from "./TimeComponent";
import TimeZoneComponent from "./TimeZoneComponent";
import EntryComponent from "./EntryComponent";
import DaysComponent from "./DaysComponent";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getManageGenreRecords, postManageGenre } from "./managegenreApi";
import "./manage-genre.css";
import { Alert } from "@material-ui/lab";
import { uuid } from "uuidv4";

const ManageGenrePage = () => {
  const [manageGenreData, setManageGenreData] = useState({});
  const [newGenre, setNewGenre] = useState("");
  const [genre, setGenre] = useState(manageGenreData.genre);
  const [contestDays, setContestDays] = useState(manageGenreData.contestDays);
  const [contestStartTime, setContestStartTime] = useState(
    manageGenreData.contestStartTime || {}
  );
  const [contestEndTime, setContestEndTime] = useState(
    manageGenreData.contestEndTime || {}
  );
  const [contestTimeZone, setContestTimeZone] = useState(
    manageGenreData.contestTimeZone
  );

  // state management for daily vote
  const [voteStartTime, setVoteStartTime] = useState(
    manageGenreData.voteStartTime || {}
  );
  const [voteEndTime, setVoteEndTime] = useState(
    manageGenreData.voteEndTime || {}
  );
  const [voteTimeZone, setVoteTimeZone] = useState(
    manageGenreData.voteTimeZone
  );
  // weekly
  const [weeklyEntry, setWeeklyEntry] = useState(manageGenreData.weeklyEntry);
  const [weeklyContestStartDay, setWeeklyContestStartDay] = useState(
    manageGenreData.weeklyContestStartDay
  );
  const [weeklyContestEndDay, setWeeklyContestEndDay] = useState(
    manageGenreData.weeklyContestEndDay
  );
  const [weeklyContestStartTime, setweeklyContestStartTime] = useState(
    manageGenreData.weeklyContestStartTime || {}
  );
  const [weeklyContestEndTime, setWeeklyContestEndTime] = useState(
    manageGenreData.weeklyContestEndTime || {}
  );
  const [weeklyContestTimeZone, setWeeklyContestTimeZone] = useState(
    manageGenreData.weeklyContestTimeZone
  );
  //monthly
  const [monthlyEntry, setMonthlyEntry] = useState(
    manageGenreData.monthlyEntry
  );
  const [monthlyContestStartDay, setMonthlyContestStartDay] = useState(
    manageGenreData.monthlyContestStartDay
  );
  const [monthlyContestStartTime, setMonthlyContestStartTime] = useState(
    manageGenreData.monthlyContestStartTime || {}
  );
  const [monthlyContestEndDay, setMonthlyContestEndDay] = useState(
    manageGenreData.monthlyContestEndDay
  );
  const [monthlyContestEndTime, setMonthlyContestEndTime] = useState(
    manageGenreData.monthlyContestEndTime || {}
  );
  const [monthlyContestTimeZone, setMonthlyContestTimeZone] = useState(
    manageGenreData.monthlyContestTimeZone
  );

  const [loaded, setLoaded] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const loadInitialData = async () => {
    const data = await getManageGenreRecords();
    const theData = data[0];
    setManageGenreData(theData);
    setGenre(theData.genre);
    setContestDays(theData.contestDays);
    setContestStartTime(theData.contestStartTime || {});
    setContestEndTime(theData.contestEndTime || {});
    setContestTimeZone(theData.contestTimeZone);
    setVoteStartTime(theData.voteStartTime || {});
    setVoteEndTime(theData.voteEndTime || {});
    setVoteTimeZone(theData.voteTimeZone);
    setWeeklyEntry(theData.weeklyEntry);
    setWeeklyContestStartDay(theData.weeklyContestStartDay);
    setWeeklyContestEndDay(theData.weeklyContestEndDay);
    setweeklyContestStartTime(theData.weeklyContestStartTime || {});
    setWeeklyContestEndTime(theData.weeklyContestEndTime || {});
    setWeeklyContestTimeZone(theData.weeklyContestTimeZone);
    setMonthlyEntry(theData.monthlyEntry);
    setMonthlyContestStartDay(theData.monthlyContestStartDay);
    setMonthlyContestStartTime(theData.monthlyContestStartTime || {});
    setMonthlyContestEndDay(theData.monthlyContestEndDay);
    setMonthlyContestEndTime(theData.monthlyContestEndTime || {});
    setMonthlyContestTimeZone(theData.monthlyContestTimeZone);

    setLoaded(true);
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const handleGenreChange = (id) => {
    const temp = genre.map((g) => ({
      ...g,
      active: g.id === id ? !g.active : g.active,
    }));
    setGenre(temp);
  };

  const handleDaysSelection = (id) => {
    const temp = contestDays.map((d) => ({
      ...d,
      active: d.id === id ? !d.active : d.active,
    }));
    setContestDays(temp);
  };

  const saveSettings = async () => {
    const payload = {
      ...manageGenreData,
      genre,
      contestDays,
      contestStartTime,
      contestEndTime,
      contestTimeZone,
      voteStartTime,
      voteEndTime,
      voteTimeZone,
      weeklyEntry,
      weeklyContestStartDay,
      weeklyContestEndDay,
      weeklyContestStartTime,
      weeklyContestEndTime,
      weeklyContestTimeZone,
      monthlyEntry,
      monthlyContestStartDay,
      monthlyContestEndDay,
      monthlyContestStartTime,
      monthlyContestEndTime,
      monthlyContestTimeZone,
    };

    try {
      await postManageGenre(payload);
      setSuccessMsg("Successfully saved manage genre settings");
    } catch (e) {
      setSuccessMsg("");
    }

    setTimeout(() => {
      setSuccessMsg("");
    }, 5000);
  };

  const addNewGenre = () => {
    setGenre((prevState) => [
      { id: uuid(), name: newGenre, active: true },
      ...prevState,
    ]);
    setNewGenre("");
  };
  return (
    <React.Fragment>
      <div
        style={{
          height: 620,
          width: "100%",
          display: "inline-block",
          paddingTop: 100,
        }}
        className="song-container"
      >
        {successMsg && <Alert severity="success">{successMsg}</Alert>}
        {loaded && (
          <React.Fragment>
            <div className="add-genre-container">
              <input
                type="text"
                className="add-new-genre-input"
                placeholder="Add New Genre"
                value={newGenre}
                onChange={(e) => setNewGenre(e.target.value)}
              />
              <button
                className="btn-save"
                onClick={addNewGenre}
                disabled={!newGenre.trim()}
              >
                Add Genre
              </button>
              <div
                className="search-by-date-btn"
                onClick={() => setNewGenre("")}
              >
                Reset
              </div>
            </div>
            <div className="manage-genre-container">
              <h3 className="section-header-genre">Manage Genre</h3>
              {genre &&
                genre.map((g) => {
                  return (
                    <div className="genre-checkbox">
                      <input
                        type="checkbox"
                        value={g.name}
                        checked={g.active}
                        onChange={() => handleGenreChange(g.id)}
                      />
                      {g.name}
                    </div>
                  );
                })}
            </div>
            <div className="daily-contest-container">
              <h3 className="section-header-genre">Daily contest days</h3>
              {contestDays &&
                contestDays.map((d) => {
                  return (
                    <div className="genre-checkbox">
                      <input
                        type="checkbox"
                        value={d.name}
                        checked={d.active}
                        onChange={() => handleDaysSelection(d.id)}
                      />
                      {d.name}
                    </div>
                  );
                })}
            </div>
            <div className="daily-contest-timing-container">
              <h3 className="section-header-genre">Contest timing</h3>
              <TimeComponent
                time={contestStartTime}
                handleTimeChange={(value) => setContestStartTime(value)}
              />
              <span className="to-label">to</span>
              <TimeComponent
                time={contestEndTime}
                handleTimeChange={(value) => setContestEndTime(value)}
              />
              <TimeZoneComponent
                timeZone={contestTimeZone}
                handleTimeChange={(value) => setContestTimeZone(value)}
              />
            </div>
            <div className="daily-contest-vote-container">
              <h3 className="section-header-genre">Daily vote timing</h3>
              <TimeComponent
                time={voteStartTime}
                handleTimeChange={(value) => setVoteStartTime(value)}
              />
              <span className="to-label">to</span>
              <TimeComponent
                time={voteEndTime}
                handleTimeChange={(value) => setVoteEndTime(value)}
              />
              <TimeZoneComponent
                timeZone={voteTimeZone}
                handleTimeChange={(value) => setVoteTimeZone(value)}
              />
            </div>

            <div className="daily-contest-container">
              <h3 className="section-header-genre">
                Weekly contest and vote timing
              </h3>
              <div className="section">
                <EntryComponent
                  entry={weeklyEntry}
                  handleEntryChange={(value) => setWeeklyEntry(value)}
                />
              </div>
              <div className="section">
                <DaysComponent
                  label="Start Day"
                  day={weeklyContestStartDay}
                  handleDaysChange={(value) => setWeeklyContestStartDay(value)}
                />
                <TimeComponent
                  time={weeklyContestStartTime}
                  handleTimeChange={(value) => setweeklyContestStartTime(value)}
                />
                <span className="to-label">to</span>
              </div>
              <div className="section">
                <DaysComponent
                  label="End Day"
                  day={weeklyContestEndDay}
                  handleDaysChange={(value) => setWeeklyContestEndDay(value)}
                />
                <TimeComponent
                  time={weeklyContestEndTime}
                  handleTimeChange={(value) => setWeeklyContestEndTime(value)}
                />
                <TimeZoneComponent
                  timeZone={weeklyContestTimeZone}
                  handleTimeChange={(value) => setWeeklyContestTimeZone(value)}
                />
              </div>
            </div>

            <div className="daily-contest-timing-container">
              <h3 className="section-header-genre">
                Monthly contest and vote timing
              </h3>
              <div className="section">
                <EntryComponent
                  entry={monthlyEntry}
                  handleEntryChange={(value) => setMonthlyEntry(value)}
                />
              </div>
              <div className="section">
                <DaysComponent
                  label="Start Day"
                  day={monthlyContestStartDay}
                  handleDaysChange={(value) => setMonthlyContestStartDay(value)}
                />
                <TimeComponent
                  time={monthlyContestStartTime}
                  handleTimeChange={(value) =>
                    setMonthlyContestStartTime(value)
                  }
                />
                <span className="to-label">to</span>
              </div>
              <div className="section">
                <DaysComponent
                  label="End Day"
                  day={monthlyContestEndDay}
                  handleDaysChange={(value) => setMonthlyContestEndDay(value)}
                />
                <TimeComponent
                  time={monthlyContestEndTime}
                  handleTimeChange={(value) => setMonthlyContestEndTime(value)}
                />
                <TimeZoneComponent
                  timeZone={monthlyContestTimeZone}
                  handleTimeChange={(value) => setMonthlyContestTimeZone(value)}
                />
              </div>
            </div>

            <div className="save-setting-container">
              <div className="search-by-date-btn" onClick={saveSettings}>
                Save Settings
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default ManageGenrePage;
