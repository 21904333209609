export const manageGenreReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOAD_GENRE_SETTINGS": {
      return {
        ...state,
        manageGenre: { ...action.payload },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
