import React from "react";
import { Drawer, Typography } from "@material-ui/core";
import userProfileDefaultPic from "../../assets/images/user-profile.png";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import InfoIcon from "@material-ui/icons/Info";

const SongInfoDrawer = ({ selectedRow, open, setOpen }) => {
  return (
    <Drawer
      className="user-drawer"
      anchor={"right"}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      {selectedRow && (
        <div
          style={{ width: "400px", textAlign: "center" }}
        >
          <img
            src={selectedRow.profilePhotoUrl || userProfileDefaultPic}
            alt="profile-pic"
            width={100}
            height={100}
            style={{ marginTop: "10px", borderRadius: '100%' }}
          />
          <Typography style={{color: 'black'}}>
            {selectedRow.firstname} {selectedRow.lastname}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <MailOutlineIcon style={{ fill: "black" }} />
            <Typography style={{color: 'black'}}> {selectedRow.email} </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <InfoIcon style={{ fill: "black" }} />
            <Typography style={{color: 'black'}}> {selectedRow.about} </Typography>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default SongInfoDrawer;
