import { DataGrid } from "@material-ui/data-grid";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import userProfileDefaultPic from "../../assets/images/user-profile.png";
import DashboardInfo from "../../dashboard/component/DashboardInfo";
import { getSongs, toggleActivate } from "../../song/services/songApi";
import { getUsers } from "../../user/services/userApi";
import "../main.css";
import AntSwitch from "./AntSwitch";

const _getTodaysDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  month = month < 10 ? `0${month}` : `${month}`;
  const date =
    today.getDate() < 10 ? `0${today.getDate()}` : `${today.getDate()}`;
  return `${year}-${month}-${date}`;
};

const getTodaysSongs = (songs) => {
  const todaysSongs = songs.filter(
    (theSong) => theSong.createdAt.split("T")[0] === _getTodaysDate()
  );
  return todaysSongs;
};

const getTodaysUsers = (users) => {
  const todaysUsers = users.filter(
    (theUser) => theUser.createdAt.split("T")[0] === _getTodaysDate()
  );
  return todaysUsers;
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const getTodaysDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = months[today.getMonth()];
  const date =
    today.getDate() + 1 < 10 ? `0${today.getDate()}` : `${today.getDate()}`;
  return `${date}-${month}-${year}`;
};

const HomePage = () => {
  const [rows, setRows] = useState([]);
  const [totalSongs, setTotalSongs] = useState(0);
  const [todayUploadedSongs, setTodayUploadedSongs] = useState(0);

  const [usersRows, setUsersRows] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [todayRegisteredUser, setTodayRegisteredUser] = useState(0);

  const todaysDate = getTodaysDate();

  const loadSongs = async () => {
    const { data } = await getSongs();
    setTotalSongs(data.filter((theSong) => theSong.active).length);
    setRows(data.slice(0, 10));
    setTodayUploadedSongs(getTodaysSongs(data).length);
  };

  const loadUsers = async () => {
    const { data } = await getUsers();

    setUsersRows(data.slice(0, 10));
    setTotalUsers(data.filter((theUser) => theUser.active).length);
    setTodayRegisteredUser(getTodaysUsers(data).length);
  };

  useEffect(() => {
    loadSongs();
    loadUsers();
  }, []);

  const activateDeactivateSongs = async (songId, active) => {
    console.log("switch change event called");
    try {
      const response = await toggleActivate(songId, active);
      await loadSongs();
    } catch (e) {
      console.log("error occurred");
    }
  };

  const columns = [
    {
      field: "",
      headerName: "Id",
      width: 200,
      renderCell: (params) => {
        return <span>{params.row._id}</span>;
      },
    },
    {
      field: "title",
      headerName: "Video Title",
      width: 150,
      renderCell: (params) => {
        return <strong style={{ color: "#000" }}>{params.row.title}</strong>;
      },
    },
    {
      field: "createdAt",
      headerName: "Date & Time",
      width: 150,
      renderCell: (params) => {
        return (
          <span>
            {moment(params.row.createdAt).format("DD-MMM-YYYY hh:mm A")}
          </span>
        );
      },
    },
    {
      field: "genre",
      headerName: "Genre",
      width: 100,
    },
    {
      field: "user.firstname",
      headerName: "Artist",
      width: 150,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <img
              src={
                (params.row.user && params.row.user.profilePhotoUrl) ||
                userProfileDefaultPic
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = userProfileDefaultPic;
              }}
              alt="profile-pic"
              width={15}
              height={15}
              style={{ borderRadius: "100%" }}
            />
            <span style={{ marginLeft: "5px", textTransform: "capitalize" }}>
              {`${params.row.user ? params.row.user.firstname : ""} ${
                params.row.user ? params.row.user.lastname : ""
              }`}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      field: "user.location.city",
      headerName: "Location",
      width: 200,
      renderCell: (params) => {
        return (
          <span>
            {params.row.user &&
              params.row.user.location &&
              `${params.row.user.location.city} ${params.row.user.location.state} ${params.row.user.location.country} `}
          </span>
        );
      },
    },
    {
      field: "competeForVideoContest",
      headerName: "In Contest",
      width: 100,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {params.row.competeForVideoContest ? (
              <span className="contest-yes"> YES </span>
            ) : (
              <span className="contest-no"> No </span>
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: "active",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <AntSwitch
            checked={params.row.active}
            onChange={() => {
              activateDeactivateSongs(params.row._id, !params.row.active);
            }}
          />
        );
      },
    },
  ];

  const usersColumns = [
    {
      field: "",
      headerName: "ID",
      width: 200,
      renderCell: (params) => {
        return <span>{params.row._id}</span>;
      },
    },
    {
      field: "firstname",
      headerName: "Artist",
      width: 150,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <img
              src={params.row.profilePhotoUrl || userProfileDefaultPic}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = userProfileDefaultPic;
              }}
              alt="profile-pic"
              width={15}
              height={15}
              style={{ borderRadius: "100%" }}
            />
            <span>{`${params.row.firstname} ${params.row.lastname}`}</span>
          </React.Fragment>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "about",
      headerName: "About",
      width: 250,
    },
  ];

  return (
    <React.Fragment>
      <div
        style={{
          minHeight: 620,
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            paddingTop: 100,
            display: "inline-block",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DashboardInfo
            count={todayUploadedSongs}
            text={"Videos uploaded today"}
            date={todaysDate}
            className="dashboard-primary"
          />
          <DashboardInfo
            count={totalSongs}
            text={"Total active videos"}
            date={todaysDate}
            className="dashboard-secondary"
          />
          <DashboardInfo
            count={todayRegisteredUser}
            text={"New user registered"}
            date={todaysDate}
            className="dashboard-default"
          />
          <DashboardInfo
            count={totalUsers}
            text={"Total active users"}
            date={todaysDate}
            className="dashboard-users"
          />
        </div>
        <h5 className="section-header">Latest Videos uploaded Today</h5>
        <div
          style={{
            height: 350,
            width: "100%",
            display: "inline-block",
          }}
          className="dashboard-song-container"
        >
          {rows && rows.length > 0 && (
            <DataGrid
              getRowId={(row) => row._id}
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              hideFooterPagination
            />
          )}
        </div>
        <h5 className="section-header">New Users registered</h5>
        <div
          style={{
            height: 350,
            width: "100%",
            display: "inline-block",
          }}
          className="user-container"
        >
          {usersRows && usersRows.length > 0 && (
            <DataGrid
              getRowId={(row) => row._id}
              rows={usersRows}
              columns={usersColumns}
              disableSelectionOnClick
              hideFooterPagination
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomePage;
