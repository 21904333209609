import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { loginPost } from "../services/LoginApi";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setGlobalAuthentication } from "../configuration/baseBackendApi";
import { EmailOutlined, Lock } from "@material-ui/icons";
import "../loginPage.css";
import "../main.css";
import { Alert } from "@material-ui/lab";

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const onLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await loginPost(email, password);
      if (response && response.access_token) {
        dispatch({
          type: "onLogin",
          payload: {
            login: true,
            access_token: response.access_token,
          },
        });
        setGlobalAuthentication(response.access_token);

        setAlertType("success");
        setAlertMessage("User logged in succesfully!");
        setShowAlert(true);
        history.push("/home");
      }
    } catch (e) {
      setAlertType("error");
      setAlertMessage("User email/password incorrect.");
      setShowAlert(true);
    }
  };

  return (
    <div className="login">
      <div className="login-container">
        <form onSubmit={onLogin}>
          <Typography className="music-card-logo-text"> MusicCard </Typography>{" "}
          {showAlert && (
            <div className="alert-field">
              <Alert
                severity={alertType}
                onClose={() => {
                  setAlertType("");
                  setAlertMessage("");
                  setShowAlert(false);
                }}
              >
                {alertMessage}
              </Alert>
            </div>
          )}
          <div className="email-field">
            <TextField
              variant="outlined"
              placeholder="Email"
              name="email"
              type="email"
              onChange={changeEmail}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="password-field">
            <TextField
              variant="outlined"
              placeholder="Password"
              type="password"
              onChange={changePassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="login-btn">
            <Button
              type="submit"
              className="btn-primary"
              variant="contained"
              color="primary"
            >
              Sign In
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
